<template>
  <div class="container">
    <div class="page-content">
      <div class="channel-panel">
        <input-select
          title="ช่องทางการสั่งซื้อ"
          text="ช่องทางการสั่งซื้อ"
          name="day"
          placeholder="ช่องทางการสั่งซื้อ"
          :disabled="true"
          valueField="sale_channel_id"
          textField="sale_channel_name"
          :options="marketList"
          @onDataChange="handleMarketPlace()"
          v-model="form.sales_channel_id"
          class="f-regular mb-2"
        >
          <template v-slot:options-name> กรุณาเลือกช่องทางการสั่งซื้อ</template>
        </input-select>

        <input-select
          title="ชื่อร้านค้า"
          text="ชื่อร้านค้า"
          name="day"
          placeholder="ชื่อร้านค้า"
          valueField="branch_id"
          textField="branch_name"
          isRequired
          v-model="form.branch_id"
          :options="marketObjList"
          :isDisplay="
            form.sales_channel_id == null || form.sales_channel_id == ''
          "
          :isValidate="$v.form.branch_id.$error"
          :v="$v.form.branch_id"
          class="f-regular mb-2"
        >
          <template v-slot:options-name> กรุณาเลือกร้านค้า</template>
        </input-select>

        <input-text
          v-if="form.sales_channel_id != 4"
          textFloat="หมายเลขสั่งซื้อ (Order No.)"
          placeholder="หมายเลขสั่งซื้อ (Order No.)"
          type="text"
          isRequired
          v-model="$v.form.order_id.$model"
          :isValidate="$v.form.order_id.$error"
          :v="$v.form.order_id"
          class="f-regular mb-2"
          :isDisplay="form.branch_id == null || form.branch_id == ''"
        />
        <input-text
          v-if="form.sales_channel_id != 4"
          textFloat="หมายเลขจัดส่ง (Tracking ID)"
          placeholder="หมายเลขจัดส่ง (Tracking ID)"
          type="text"
          isRequired
          v-model="$v.form.tracking_no.$model"
          :isValidate="$v.form.tracking_no.$error"
          :v="$v.form.tracking_no"
          class="f-regular mb-2"
          :isDisplay="form.branch_id == null || form.branch_id == ''"
        />
      </div>
      <div class="button-panel">
        <b-button
          class="w-100"
          :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
          @click="addMarketPlace()"
          >ยืนยัน</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  validations: {
    form: {
      branch_id: { required },
      order_id: {
        required: requiredIf(function () {
          return this.form.sales_channel_id != 4;
        })
      },
      tracking_no: {
        required: requiredIf(function () {
          return this.form.sales_channel_id != 4;
        })
      }
    }
  },
  data() {
    return {
      marketList: [],
      form: {
        branch_id: null,
        sales_channel_id: null,
        order_id: "",
        tracking_no: ""
      },
      marketObjList: []
    };
  },
  created() {
    this.getMarketPlace();
  },
  computed: {},
  methods: {
    async getMarketPlace() {
      const data = await this.$axios(
        `${process.env.VUE_APP_API}/api/v1/User/get/marketplace`
      );
      this.marketList = data.detail.disconnect_marketplace.filter(el =>
        data.detail.marketplace.find(
          eles => el.sale_channel_id == eles.salesChannelId
        )
      );

      // this.sales_channel_id = findMarket.sale_channel_id;
    },
    async addMarketPlace() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      const res = await this.$axios.post(
        `${process.env.VUE_APP_API}/api/v1/User/connect/marketplace`,
        this.form
      );
      if (res.result == 1) {
        this.$swal("สำเร็จ", "บันทึกสำเร็จ", "success").then(
          val => val && this.$router.push(`/marketplace`)
        );
      } else {
        this.$swal("error", res.message, "error");
      }
    },
    handleMarketPlace() {
      const market = this.marketList.find(
        el => el.sale_channel_id == this.form.sales_channel_id
      );
      if (market) {
        this.marketObjList =
          market.marketplace.length > 0 ? market.marketplace : [];
        if (market.marketplace.length == 1) {
          this.form.branch_id = market.marketplace[0].branch_id;
        } else if (market.marketplace.length == 0) {
          this.form.branch_id = null;
        }
      } else {
        this.marketObjList = [];
      }
      // this.marketObjList =
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 0 1rem;
  width: 100%;
  height: 100%;
  img {
    width: 50px;
    height: 50px;
    // border-radius: 50%;
  }
  td {
    padding: 5px 0px;
  }
}
.button-panel {
  position: absolute;

  width: calc(100% - 2rem);
  left: 0;
  margin: 1rem;
  bottom: 0;
}
.channel-panel {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
</style>
